import React from 'react';
import PropTypes from 'prop-types';

const QuestionsGrid = ({ gridItems }) => (
  <div className="columns is-multiline" style={{ marginTop: '40px' }}>
    {gridItems.map((item) => (
      <div key={item.question} className="column is-6">
        {/* <section className="section"> */}
          <div className="has-text-weight-bold">{item.question}</div>
          <div className="">{item.answer}</div>
        {/* </section> */}
      </div>
    ))}
  </div>
);

QuestionsGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
};

export default QuestionsGrid;
