import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import WaitingList from './WaitingList';
import { Link } from 'gatsby';
// import EmailListForm from './EmailListForm';

const Pricing = ({ data, planChoice }) => {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="columns is-centered">
      <div className="column is-2"></div>
      {data.map((price) => (
        <div key={price.plan} className="column is-3 container">
          <div className="card">
            <div className="card-header has-background-info">
              <div className="card-header-title is-size-5 is-centered has-text-white">
                {price.plan}
              </div>
            </div>
            <div className="card-content">
              <div className="has-text-weight-semibold">{price.description}</div>
              <div className="is-size-3 has-text-weight-bold has-text-primary has-text-centered">
                {planChoice ? price.priceMonth : price.priceYear}
              </div>
              {price.plan === 'Team' ? (
                <>
                  <div className="is-size-7"> &nbsp; </div>
                  <a className="button is-primary is-fullwidth" href="mailto: team@cpeu.org">
                    Contact Us
                  </a>
                </>
              ) : (
                <>
                  <div className="is-size-7 has-text-grey-light has-text-centered">/ month</div>
                  <Link className="button is-primary is-fullwidth" to="/signup">
                    Subscribe Now
                  </Link>
                </>
              )}
              {/* <WaitingList fullwidth location={'Pricing'} url={'/pricing'} /> */}
              <ul>
                {price.items.map((item) => (
                  <li key={item} className="is-size-6">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
      <div className="column is-2"></div>
    </div>
  );
};

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      priceMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      priceYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

export default Pricing;
