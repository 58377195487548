import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Pricing from '../components/Pricing';
import Questions from '../components/Questions';
import Content, { HTMLContent } from '../components/Content';

export const PricingPageTemplate = ({
  image,
  title,
  subtitle,
  pricing,
  heading,
  description,
  setPlanChoice,
  planChoice,
  questions,
  contentComponent,
}) => {
  const DescriptionContent = contentComponent || Content;
  return (
    <>
      <Hero
        image={!image.childImageSharp ? image : image.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
      />
      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="columns is-centered" style={{ marginTop: '100px' }}>
              <div className="column is-full">
                <h2 className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info has-text-centered">
                  {pricing.heading}
                </h2>
                <p className="is-size-5 has-text-centered">{pricing.description}</p>
                <div className="layout-plans__switch">
                  <div className="switch ">
                    <input
                      className="switch__input"
                      type="checkbox"
                      id="plans"
                      data-action="plan-price-toggle"
                      onChange={(e) => {
                        setPlanChoice(e.target.checked);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: 'buttonClick',
                          category: 'Plan Toggle Button',
                          action: `Click ${e.target.checked}`,
                          label: 'Pricing Page Toggle Plan',
                        });
                      }}
                    ></input>
                    <div className="switch__toggle">
                      <label className="switch__label switch__label--value" htmlFor="plans">
                        Annual
                      </label>
                      <label className="switch__label switch__label--checked" htmlFor="plans">
                        Monthly
                      </label>
                    </div>
                  </div>
                  <p className="text-body-s">Get one and a half months free with annual plan.</p>
                </div>
              </div>
            </div>
            <Pricing data={pricing.plans} planChoice={planChoice} />
            {/* <div className="columns is-centered" style={{ marginTop: '100px' }}> */}
            {/* <div className="column is-7"> */}
            <div className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info">
              {heading}
            </div>
            <DescriptionContent className="content is-size-5" content={description} />
            {/* </div> */}
            {/* </div> */}
            {/* <div className="columns is-centered" style={{ marginTop: '100px' }}> */}
            {/* <div className="column is-7"> */}
            <div
              className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info"
              style={{ marginTop: '100px' }}
            >
              {questions.heading}
            </div>
            <Questions gridItems={questions.blurbs} />
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

PricingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
  heading: PropTypes.string,
  description: PropTypes.string,
  questions: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const PricingPage = ({ data }) => {
  // const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark;
  const [planChoice, setPlanChoice] = useState(false);

  return (
    <Layout>
      <PricingPageTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        pricing={frontmatter.pricing}
        heading={frontmatter.heading}
        description={data.markdownRemark.html}
        setPlanChoice={setPlanChoice}
        planChoice={planChoice}
        questions={frontmatter.questions}
      />
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heading
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            priceMonth
            priceYear
          }
        }
        questions {
          heading
          blurbs {
            question
            answer
          }
        }
      }
      html
    }
  }
`;
